




















































































































import Vue, { PropOptions, PropType } from 'vue';
import { isHomePage } from '~/router/index';
import PrimaryMenu from '~/components/PrimaryMenu.vue';
import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
import { isTouchDevice } from '~/utils';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import { isHealthPath } from '@/router';
import TheBell from '@/components/Pusher/TheBell/TheBell.vue';

const isTouch = isTouchDevice();
let timeoutId: NodeJS.Timeout | null = null;

export default Vue.extend({
  components: {
    PrimaryMenu,
    NavMenuContainer,
    TheBell,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    } as PropOptions<boolean>,
    doAuthorization: {
      type: Function,
      required: true,
    } as PropOptions<() => void>,
    userAvatarSrc: {
      type: String,
      required: true,
    } as PropOptions<string>,
    userIntegerId: {
      type: null as unknown as PropType<number | null>,
      validator: (v: any) => typeof v === 'number' || v === null,
      required: true,
    } as PropOptions<number>,
    logout: {
      type: Function,
      required: true,
    } as PropOptions<() => void>,
    primaryNavMenuItems: {
      type: Array,
      required: true,
    } as PropOptions<Array<MenuItem>>,
    showNav: {
      type: Function,
      required: true,
    } as PropOptions<() => void>,
  },
  data () {
    return {
      isNavMenuVisible: false as boolean,
    };
  },
  computed: {
    isHealth (): boolean {
      return isHealthPath(this.$route.path);
    },
    isHomePage () {
      return isHomePage(this.$route.name);
    },
    topHeaderHealth () : Record<string, boolean> {
      return {
        'top-header__health': this.isHealth,
      };
    },
  },
  watch: {
    // при смене роута убираем NavMenu
    '$route.path' (): void {
      this.isNavMenuVisible = false;
    },
  },
  async mounted () {
    // при клике мимо NavMenu, закрываем NavMenu
    document.addEventListener('click', () => {
      this.isNavMenuVisible = false;
    }, false);

    // * не успевает найти колокольчик
    await this.$nextTick;
    const theBell = document.querySelector('.the-bell__bell');
    theBell?.addEventListener('click', this.handleBellClick);
  },
  beforeDestroy () {
    this.clearHideMenuTimeOut();
    document.removeEventListener('click', this.handleBellClick);
  },
  methods: {
    handleBellClick () {
      this.$sendYandexMetrika({
        level1: 'Клик_Колокольчик',
      });
    },

    onClickAvatar (): void {
      this.$sendAnalyticsEvent({
        event: 'Личный кабинет_переходы профиль',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: '',
        element: '',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },
    onClickSearch (): void {
      this.$sendYandexMetrika({
        level1: 'Событие_Поиск',
        level6: 'Открытие поиска',
      });

      this.$emit('click-on-search');
    },
    handleAvatarMenuItemClick (event: string, title: string): void {
      this.$sendAnalyticsEvent({
        event,
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: '',
        element: '',
        item: '',
        action: `Клик на иконку_${title}`,
        value: 1,
        currency: 'piece',
      });
    },
    handleLogoutClick (): void {
      this.$sendYandexMetrika({
        level1: 'Авторизация',
        level8: 'Выход',
      });

      this.logout();
      this.handleAvatarMenuItemClick('Авторизация', 'Выход');
    },
    clearHideMenuTimeOut (): void {
      timeoutId && clearTimeout(timeoutId);
      timeoutId = null;
    },
    showNavMenu (): void {
      if (!isTouch && !this.isNavMenuVisible && !this.isHealth) {
        this.isNavMenuVisible = true;
      }
    },
    onClickShowAllNotifications () {
      this.$sendAnalyticsEvent({
        event: 'Личный кабинет_переход ко всем уведомлениям_колокольчик',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: `/profile/user/${this.userIntegerId}/notifications/`,
        element: '',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },
    hideNavMenu () {
      if (!isTouch && this.isNavMenuVisible) {
        this.isNavMenuVisible = false;
      }
    },
    onLogoClick (): void {
      this.$emit('click-on-logo');
    },
    onNavMenuMouseLeave (): void {
      timeoutId = setTimeout(() => {
        if (this.isNavMenuVisible) {
          this.isNavMenuVisible = false;
        }
      }, 1000);
    },
    onNavMenuMouseEnter (): void {
      this.clearHideMenuTimeOut();
    },
    async onLogoFocus () {
      // Непонятно зачем это
      this.showNavMenu();
      await this.$nextTick();
      if (this.$refs.whiteNavMenu instanceof HTMLElement) {
        this.$refs.whiteNavMenu.focus();
      }
    },
  },
});
